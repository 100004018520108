.schedules-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.schedules-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.schedules-list {
  list-style-type: none;
  padding: 0;
}

.schedule-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.schedule-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.schedule-info {
  flex: 1;
}

.schedule-info h2 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 1.2em;
}

.schedule-info span {
  font-size: 0.9em;
  color: #666;
}

.button-group {
  display: flex;
  gap: 10px;
}

.schedule-item button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.schedule-item button:hover {
  background-color: #0056b3;
}

.schedule-item .delete-btn {
  background-color: rgb(241, 23, 23)
}

.schedule-info span {
  display: block;
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}