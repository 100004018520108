.edit-member-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .edit-member-page h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 30px;
  }
  
  .form-group h2 {
    margin-bottom: 10px;
    color: #444;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  
  .checkbox-item input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .preference-buttons {
    margin-bottom: 10px;
  }
  
  .preference-buttons button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #3134e6;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .preference-buttons button:hover {
    background-color: #6200ff;
  }
  
  .date-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .unavailable-dates-list {
    list-style-type: none;
    padding: 0;
  }
  
  .unavailable-dates-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }
  
  .unavailable-dates-list button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 2px 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .update-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  
  input[type="text"], .react-datepicker-wrapper {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    cursor: pointer;
  }