.unauthorized-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: calc(100vh - 60px);
    background-color: #f0f2f5;
    padding: 4rem 2rem;
  }
  
  .content-container {
    background-color: white;
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 800px;
    width: 100%;
  }
  
  .unauthorized-page h1 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  
  .message-container {
    margin: 2rem 0;
    padding: 1.5rem;
    background-color: #ffeeee;
    border-radius: 8px;
  }
  
  .message-container h2 {
    color: #d32f2f;
    margin-bottom: 1rem;
  }
  
  .message-container p {
    color: #333;
    font-size: 1.1rem;
  }
  
  .authorized-emails {
    margin-top: 2rem;
    text-align: left;
  }
  
  .authorized-emails h3 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .authorized-emails ul {
    list-style-type: none;
    padding: 0;
  }
  
  .authorized-emails li {
    color: #666;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .unauthorized-page {
      padding: 2rem 1rem;
    }
  
    .content-container {
      padding: 2rem;
    }
  
    .unauthorized-page h1 {
      font-size: 2rem;
    }
  }