.navbar {
  background-color: #333;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 60px;
  padding: 0 20px;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navbar-links a:hover,
.navbar-links a.active {
  background-color: #007bff;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-email {
  color: #fff;
  margin-right: 15px;
  font-size: 0.9rem;
}

.logout-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    height: auto;
    padding: 10px 20px;
  }

  .navbar-links {
    margin-bottom: 10px;
  }

  .user-info {
    flex-direction: column;
    align-items: stretch;
  }

  .user-email {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .logout-button {
    width: 100%;
  }
}