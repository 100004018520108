.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    font-family: 'Arial', sans-serif;
  }
  
  .login-container {
    background-color: white;
    padding: 48px 40px 36px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 400px;
  }
  
  .department-title {
    text-align: center;
    color: #333;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    line-height: 1.3;
  }
  
  .login-title {
    text-align: center;
    color: #1a1a1a;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  
  .input-group {
    margin-bottom: 24px;
    position: relative;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .input-group input {
    width: 100%;
    height: 44px;
    padding: 0 15px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 16px;
    color: #202124;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
    line-height: 42px;
  }
  
  .input-group input:focus {
    outline: none;
    border-color: #1a73e8;
  }
  
  .login-button,
  .google-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .login-button {
    background-color: #4a90e2;
    color: white;
    margin-bottom: 1rem;
  }
  
  .login-button:hover {
    background-color: #3a7bc8;
  }
  
  .google-button {
    background-color: white;
    color: #757575;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-button:hover {
    background-color: #f5f5f5;
  }
  
  .google-button img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1.5rem 0;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }
  
  .divider span {
    padding: 0 0.75rem;
    color: #757575;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .error {
    color: #e53935;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    height: 24px;
  }

  .checkbox-group input[type="checkbox"] {
    margin-right: 8px;
  }

  .checkbox-group label {
    font-size: 14px;
    color: #5f6368;
  }
  
  form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }