.team-members-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.team-members-page h1 {
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.member-count {
  font-size: 1.2rem;
  color: #666;
  margin-right: 20px;
}

.button-group {
  display: flex;
  gap: 15px;
}

.remove-dates-btn,
.add-member-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.remove-dates-btn {
  background-color: #ffc107;
  color: #000;
}

.remove-dates-btn:hover {
  background-color: #e0a800;
}

.add-member-btn {
  background-color: #28a745;
  color: white;
  text-decoration: none;
  display: inline-block;
}

.add-member-btn:hover {
  background-color: #218838;
}

.search-bar {
  width: 100%;
  margin-bottom: 25px;
}

.search-bar input {
  width: 100%;
  padding: 12px 15px;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.filter-buttons {
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
}

.filter-btn {
  padding: 10px 20px;
  border: 1px solid #007bff;
  background-color: white;
  color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  min-width: 100px;
  text-align: center;
}

.filter-btn:hover {
  background-color: #e6f2ff;
}

.filter-btn.active {
  background-color: #007bff;
  color: white;
}

.member-list {
  list-style-type: none;
  padding: 0;
}

.member-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.member-info {
  flex: 1 1 70%;
  min-width: 200px;
}

.member-info h2 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 1.4rem;
}

.member-info p {
  margin: 0 0 10px 0;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.5;
}

.info-label {
  font-weight: bold;
  color: #444;
  margin-right: 8px;
}

.info-value {
  color: #007bff;
  word-wrap: break-word;
}

.inactive-status {
  color: #ff0000;
  font-weight: bold;
}

.active-status {
  color: #008000;
  font-weight: bold;
}

.member-actions {
  display: flex;
  gap: 10px;
  flex: 0 0 auto;
  margin-left: auto;
}

.edit-btn,
.delete-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  transition: background-color 0.3s ease;
}

.edit-btn {
  background-color: #007bff;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
}

.delete-btn:hover {
  background-color: #c82333;
}

.no-members {
  text-align: center;
  color: #666;
  font-style: italic;
  margin-top: 30px;
  font-size: 1.2rem;
}

.delete-container {
  position: relative;
}

.delete-container .confirm-dialog {
  position: absolute;
  top: 100%;
  right: 0;
  width: 250px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.delete-container .confirm-dialog p {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.delete-container .confirm-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.delete-container .confirm-delete,
.delete-container .cancel-delete {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.delete-container .confirm-delete {
  background-color: #dc3545;
  color: white;
}

.delete-container .cancel-delete {
  background-color: #6c757d;
  color: white;
}

.remove-dates-container {
  position: relative;
}

.remove-dates-container .confirm-dialog {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.remove-dates-container .confirm-dialog p {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.remove-dates-container .confirm-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.remove-dates-container .confirm-delete,
.remove-dates-container .cancel-delete {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.remove-dates-container .confirm-delete {
  background-color: #dc3545;
  color: white;
}

.remove-dates-container .cancel-delete {
  background-color: #6c757d;
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.success-message {
  color: #28a745;
  font-weight: bold;
}

@media (max-width: 768px) {
  .sub-header {
    flex-direction: column;
    align-items: stretch;
  }

  .member-count {
    margin-bottom: 15px;
  }

  .button-group {
    flex-direction: column;
  }

  .remove-dates-btn,
  .add-member-btn {
    width: 100%;
  }

  .filter-buttons {
    justify-content: space-between;
  }

  .filter-btn {
    flex: 1;
  }

  .member-item {
    flex-direction: column;
  }

  .member-actions {
    margin-top: 15px;
    width: 100%;
    justify-content: flex-end;
  }
}