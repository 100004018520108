.create-rota-page {
  max-width: 3000px;
  margin: 0 auto;
  padding: 20px;
}

.date-picker-container {
  margin-bottom: 20px;
}

.date-picker-wrapper {
  display: flex;
  gap: 10px;
}

.selected-dates ul {
  list-style-type: none;
  padding: 0;
}

.selected-dates li {
  margin-bottom: 5px;
}

.generate-button, .export-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.generated-rota {
  margin-top: 30px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.generated-rota table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.generated-rota th, 
.generated-rota td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: left;
  min-width: 110px;
  max-width: 150px;
}

.generated-rota th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 10;
}

.generated-rota th:first-child,
.generated-rota td:first-child {
  min-width: 90px;
  max-width: 90px;
}

.generated-rota th:nth-child(2),
.generated-rota td:nth-child(2) {
  min-width: 70px;
  max-width: 70px;
}

.generated-rota select {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  background-color: transparent;
  font-size: 14px;
}

.export-button {
  margin-top: 20px;
}

/* Ensure dropdown options are fully visible */
.generated-rota select option {
  width: auto;
  min-width: 100%;
}

.algorithm-selection {
  margin-bottom: 20px;
}

.algorithm-selection label {
  display: block;
  margin-bottom: 10px;
}

.algorithm-selection input[type="radio"] {
  margin-right: 10px;
}