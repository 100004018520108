.home-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 60px);
  background-color: #f0f2f5;
  padding: 4rem 2rem;
}

.content-container {
  background-color: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.home-page h1 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.welcome-text {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.logo-container {
  margin: 2rem 0;
}

.logo {
  max-width: 300px;
  height: auto;
}

@media (max-width: 768px) {
  .home-page {
    padding: 2rem 1rem;
  }

  .content-container {
    padding: 2rem;
  }

  .home-page h1 {
    font-size: 2rem;
  }

  .logo {
    max-width: 250px;
  }
}