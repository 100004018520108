.add-member-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .add-member-page h1 {
    text-align: center;
    color: #333;
  }
  
  .add-member-page form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .add-member-page label {
    font-weight: bold;
  }
  
  .add-member-page input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .add-member-page .qualifications {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .add-member-page button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-member-page button:hover {
    background-color: #0056b3;
  }