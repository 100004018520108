.view-rota-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.view-rota-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: bold;
}

.rota-table-container {
  overflow-x: auto;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.rota-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.rota-table th,
.rota-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.rota-table th {
  background-color: #f1f3f5;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.rota-table tr:last-child td {
  border-bottom: none;
}

.rota-table .date-header,
.rota-table .event-header,
.rota-table .position-header {
  font-weight: bold;
  font-size: 1rem;
}

.rota-table .date-cell {
  font-weight: bold;
  font-size: 1.1rem;
  background-color: #f8f9fa;
  vertical-align: middle;
}

.rota-table .event-cell {
  font-weight: bold;
  color: #007bff;
}

.rota-table .assignment-cell {
  font-size: 1rem;
}

.rota-table select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1rem;
}

.rota-table tr.new-date td {
  border-top: 10px solid #f8f9fa;
}

.rota-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.edit-button,
.save-button,
.cancel-button,
.export-button,
.count-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button {
  background-color: #007bff;
  color: white;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.cancel-button {
  background-color: #dc3545;
  color: white;
}

.export-button {
  background-color: #17a2b8;
  color: white;
}

.count-button {
  background-color: #6c757d;
  color: white;
}

.edit-button:hover,
.save-button:hover,
.cancel-button:hover,
.export-button:hover,
.count-button:hover {
  opacity: 0.9;
}

.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 50px;
}

.assignment-counts-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.assignment-counts-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.assignment-counts-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
}

.assignment-counts-content ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.assignment-counts-content li {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #555;
}

.assignment-counts-content button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assignment-counts-content button:hover {
  background-color: #0056b3;
}

.conflict-button {
  background-color: #ffc107;
  color: black;
}

.conflicts-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.conflicts-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.conflicts-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
}

.conflicts-content ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.conflicts-content li {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #555;
}

.conflicts-content button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.conflicts-content button:hover {
  background-color: #0056b3;
}